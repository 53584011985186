import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const faqIndexConfig: IndexPageConfig = {
  templatePath: "./src/features/faq/templates/faqs-template.tsx",
  pagePath: PathPrefix.FAQs,
  searchConfig: { filters: "type:faq" },
  attributes: ["type", "time", "title", "url"],
  indexes: ["featured", "newest", "oldest", "alphabetical"],
  filters: [
    {
      attribute: "fields.topic",
      title: "search-filter-topics"
    }
  ],
  defaultSearchIndex: "newest"
}
